import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, catchError, throwError } from 'rxjs';
import { Globais } from 'src/app/area-restrita/shared/globais/globais';
import { LocalStorageService } from 'src/app/area-restrita/shared/services/local-storage.service';
import type { UsuarioLogin } from 'src/app/shared/models/usuario-login.interface';
import { environment } from 'src/environments/environment';

export interface TrocaSenha {
  senhaAntiga: string;
  senhaNova: string;
  confirmaSenha?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SenhaService {
  private readonly http = inject(HttpClient);
  private readonly localStorage = inject(LocalStorageService);

  private readonly headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded'
  });
  private readonly user: BehaviorSubject<UsuarioLogin | null> = new BehaviorSubject<UsuarioLogin | null>(null);
  public user$: Observable<UsuarioLogin | null> = this.user.asObservable();

  private readonly env = environment;

  public alterarSenha(newPassword: TrocaSenha): Observable<TrocaSenha> {
    const body = new HttpParams().set('senhaAntiga', newPassword.senhaAntiga).set('senhaNova', newPassword.senhaNova).set('login', 'usuario');
    return this.http.post<TrocaSenha>(`${this.env.apiUrl}trocar-senha`, body.toString(), { headers: this.headers }).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Erro durante o envio de dados:', error);
        return throwError(() => error);
      })
    );
  }

  public esqueciSenha(user: string): Observable<UsuarioLogin> {
    const body = new HttpParams().set('usuario', user);
    return this.http.post<UsuarioLogin>(`${Globais.urlToken}site-pge/api/esqueci-senha`, body.toString(), { headers: this.headers });
  }

  public confirmarEsqueciSenha(): Observable<string> {
    return this.http.post<string>(`${Globais.urlToken}site-pge/api/confirmar-esqueci-senha/${this.localStorage.get('idUsuario')}`, { headers: this.headers });
  }

  public setUserInfo(userInfo: UsuarioLogin): void {
    this.user.next(userInfo);
  }
}
